///
/// @file template_api/products/product_grid_v1/src/product_grid_v1.scss
///
/// \brief Styles for product grid - v1
///
///

@import '../../../../scss/theme-bootstrap';

$grid-columns: 1 2 3 4;

.product-grid {
  width: 100%;
  position: relative;
  &-wrapper {
    width: 100%;
    order: 3;
    max-width: $mpp-container-max-width;
    margin: 0 auto;
    @include breakpoint($medium-up) {
      border-top: 1px solid $light-gray;
    }
  }
  &__content {
    @include pie-clearfix;
    width: 100%;
    margin: 0 auto 10px;
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    position: relative;
    &:after {
      position: absolute;
      width: 100%;
      top: auto;
      bottom: 0;
      #{$ldirection}: 0;
      width: 100%;
      height: 6px;
      content: ' ';
    }
    &.item-active:after {
      background: $color-gray;
    }
    .mpp-container--has-filters & {
      // initially hide items only if mixitup filters is enabled
      display: none;
    }
  }
  &--small-cols-1,
  &--small-cols-2,
  &--small-cols-3,
  &--small-cols-4 {
    .product-grid__item {
      float: $ldirection;
    }
  }
  // we show 1-up by default
  &__item {
    float: $ldirection;
    width: 100%;
    .product-grid--carousel &:not(.slick-slide):not(:nth-child(n + 2)) {
      display: block;
    }
    .product-grid--carousel &:not(.slick-slide):nth-child(n + 2) {
      display: none;
    }
    &--colspan-2 {
      width: 100%;
      .tout--portrait {
        max-width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: auto;
        }
      }
    }
    .tout--portrait {
      max-width: inherit;
      margin: 0 auto;
      padding: 10px 10px 0;
    }
    a.button,
    .product-brief__quickshop-launch--small {
      width: 100%;
    }
    .product-brief__header {
      width: 185px;
      margin: 0 auto;
    }
  }
  @each $item in $grid-columns {
    &--small-cols-#{$item} .product-grid__item {
      width: 100% / $item;
      @at-root .product-grid--carousel#{&}:not(.slick-slide):not(:nth-child(n + #{$item + 1})) {
        display: block;
      }
      @at-root .product-grid--carousel#{&}:not(.slick-slide):nth-child(n + #{$item + 1}) {
        display: none;
      }
      a.button {
        width: 100%;
      }
      .product-brief__quickshop-launch--small,
      .product-brief__header {
        width: 100%;
      }
      .tout--portrait {
        padding: 0;
      }
    }
    @if $item == 1 {
      &--small-cols-#{$item} .product-grid__item {
        .tout--portrait {
          max-width: inherit;
          margin: 0 auto;
          padding: 10px 10px 0;
        }
        a.button {
          width: 100%;
        }
        .product-brief__quickshop-launch--small {
          width: 100%;
        }
        .product-brief__header {
          width: 185px;
          margin: 0 auto;
        }
      }
    }
  }
  @include breakpoint($portrait-up) {
    &__item {
      float: none;
      width: 33.333%;
      display: block;
      position: relative;
      .product-grid--carousel &:not(.slick-slide):not(:nth-child(n + 4)) {
        display: block;
      }
      .product-grid--carousel &:not(.slick-slide):nth-child(n + 4) {
        display: none;
      }
      a.button {
        width: 100%;
      }
      .product-brief__quickshop-launch--small,
      .product-brief__header {
        width: 100%;
      }
      .tout--portrait {
        padding: 0;
      }
    }
    @each $item in $grid-columns {
      &--portrait-cols-#{$item} .product-grid__item {
        width: 100% / $item;
        @at-root .product-grid--carousel#{&}:not(.slick-slide):not(:nth-child(n + #{$item + 1})) {
          display: block;
        }
        @at-root .product-grid--carousel#{&}:not(.slick-slide):nth-child(n + #{$item + 1}) {
          display: none;
        }
        a.button {
          width: 100%;
        }
        .product-brief__quickshop-launch--small,
        .product-brief__header {
          width: 100%;
        }
        .tout--portrait {
          padding: 0;
        }
      }
      @if $item == 1 {
        &--portrait-cols-#{$item} .product-grid__item {
          width: auto;
          float: none;
          a.button {
            width: 100%;
          }
          .product-brief__quickshop-launch--small {
            width: 100%;
          }
          .product-brief__header {
            width: 185px;
            margin: 0 auto;
          }
          .tout--portrait {
            max-width: inherit;
            margin: 0 auto;
            padding: 10px 10px 0;
          }
        }
      }
    }
  }
  @include breakpoint($landscape-up) {
    &__item {
      float: none;
      width: 25%;
      display: block;
      position: relative;
      .product-grid--carousel &:not(.slick-slide):not(:nth-child(n + 5)) {
        display: block;
      }
      .product-grid--carousel &:not(.slick-slide):nth-child(n + 5) {
        display: none;
      }
      &--colspan-2 {
        width: 50%;
        .product-grid--carousel &:not(.slick-slide):not(:nth-child(n + 3)) {
          display: block;
        }
        .product-grid--carousel &:not(.slick-slide):nth-child(n + 3) {
          display: none;
        }
        .tout--portrait {
          max-width: 100%;
          display: flex;
          justify-content: center;
          img {
            width: auto;
          }
        }
      }
      a.button {
        width: 100%;
      }
      .product-brief__quickshop-launch--small {
        width: auto;
      }
      .tout--portrait {
        padding: 0;
      }
    }
    @each $item in $grid-columns {
      &--landscape-cols-#{$item} .product-grid__item {
        width: 100% / $item;
        @at-root .product-grid--carousel#{&}:not(.slick-slide):not(:nth-child(n + #{$item + 1})) {
          display: block;
        }
        @at-root .product-grid--carousel#{&}:not(.slick-slide):nth-child(n + #{$item + 1}) {
          display: none;
        }
        a.button {
          width: 100%;
        }
        .product-brief__quickshop-launch--small,
        .product-brief__header {
          width: 100%;
        }
        .tout--portrait {
          padding: 0;
        }
      }
      @if $item == 1 {
        &--landscape-cols-#{$item} .product-grid__item {
          width: auto;
          float: none;
          a.button,
          .product-brief__quickshop-launch--small {
            width: 100%;
          }
          .product-brief__header {
            width: 185px;
            margin: 0 auto;
          }
          .tout--portrait {
            max-width: inherit;
            margin: 0 auto;
            padding: 10px 10px 0;
          }
        }
      }
    }
  }
  // All the wrappers inside need to have height:100% for the inline tout to not break
  &__tout {
    height: 100%;
    width: 100%;
  }
  .no-matches {
    display: none;
  }
  &.product-grid--carousel {
    .product-grid__item {
      float: $ldirection;
      height: auto;
    }
    .slick-track {
      display: flex;
    }
    .slick-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    &-peeking { 
      .slick-list {
        @include swap_direction(padding, 0 10% 0 0);
        @include breakpoint($large-up) {
          padding: 0;
        }
      }
    }
    .select-box__options {
      height: 65px;
      overflow: auto;
    }
    .carousel-controls {
      top: 40%;
      @include breakpoint($large-up) {
        top: 50%;
      }
      .slick-next,
      .slick-prev {
        height: 96px;
        width: 60px;
        min-width: auto;
        &::before,
        &::before {
          font-size: 25px;
          height: 40px;
          width: 25px;
        }
      }
    }
    @include breakpoint($large-up) {
      .slick-prev {
        #{$ldirection}: -30px;
      }
      .slick-next {
        #{$rdirection}: -30px;
      }
    }
  }
}
